// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
    box-sizing:border-box;
}

.form-container{
    width: 80%;
    height: auto;
    margin: 10px;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: left;
    border: 1px solid black;
    border-radius: 20px;
    flex-shrink: 0;
}

.form-container h1{
    text-align: center;
}

.main-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/JokeApp/index.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,QAAQ;IACR,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;AACf","sourcesContent":["*{\r\n    box-sizing:border-box;\r\n}\r\n\r\n.form-container{\r\n    width: 80%;\r\n    height: auto;\r\n    margin: 10px;\r\n    padding: 20px 40px;\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 5px;\r\n    text-align: left;\r\n    border: 1px solid black;\r\n    border-radius: 20px;\r\n    flex-shrink: 0;\r\n}\r\n\r\n.form-container h1{\r\n    text-align: center;\r\n}\r\n\r\n.main-container{\r\n    display: flex;\r\n    justify-content: center;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    width: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
