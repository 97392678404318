import JokeApp from './components/JokeApp'
import './App.css';

function App() {
  return (
    <div className="App">
      <JokeApp/>
    </div>
  );
}

export default App;
